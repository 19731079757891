import React, { useState, useEffect, useRef, memo } from 'react';
import { sessionKit } from './sessionKit';

const LoginButton = memo(({ onLogin, showLogout }) => {  // Add `showLogout` prop
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const hasRestoredSession = useRef(false);

  useEffect(() => {
    const restoreSession = async () => {
      if (hasRestoredSession.current) return;
      console.log('Restoring session...');
      const restoredSession = await sessionKit.restore();
      console.log('Session restored:', restoredSession);
      if (restoredSession) {
        console.log('Restored session actor:', restoredSession.actor);
        setAccount(restoredSession.actor);
        onLogin(restoredSession);
      }
      hasRestoredSession.current = true;
    };
    restoreSession();

    return () => {
      console.log('Cleaning up LoginButton...');
      hasRestoredSession.current = false;
    };
  }, [onLogin]);

  const handleLogin = async () => {
    console.log('Logging in...');
    try {
      setLoading(true);

      const response = await sessionKit.login();
      const { session } = response;
      if (session) {
        console.log('Login successful:', session.actor);
        setAccount(session.actor);
        onLogin(session);

if (typeof session.transact === 'function') {
  console.log('Session has the transact method');
} else {
  console.error('Session does not have the transact method');
}

      }
    } catch (error) {
      console.error('Login failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    console.log('Logging out...');
    try {
        // Check if there is an active session
        if (account) {
            await sessionKit.logout();
            setAccount(null);
            onLogin(null);
            hasRestoredSession.current = false;
        } else {
            console.warn('No active session found. Cannot log out.');
        }
    } catch (error) {
        console.error('Logout failed:', error);
        if (error && error.message) {
            console.error('Error message:', error.message);
          }
        }
    };

  return (
    <div className="login-button">
      {!account ? (
        <button onClick={handleLogin} disabled={loading}>
          {loading ? 'Logging in...' : 'Login with Anchor'}
        </button>
      ) : (
        showLogout && (
          <button onClick={handleLogout}>Logout</button>
        )
      )}
    </div>
  );
});

export default LoginButton;