import React, { useState, useEffect, useCallback } from 'react';  // Import useCallback here
import logo from './logo.svg';  // Import the logo here
import mine from './mine.jpeg';  // Import the logo here
import './App.css';
import LoginButton from './LoginButton';  // Import the LoginButton component
//import { sessionKit } from './sessionKit';

const pleaseLoginToMine = [
  "Ah, the enigmatic visitor! You’ve got to be logged in to access the treasure trove of resources.",
  "Greetings, cosmic wanderer! To mine resources, you’ll need to show your login credentials. No ticket, no show!",
  "Well, if it isn’t our mysterious guest! Logging in is required to start mining. It’s like a secret password to the galaxy’s goodies.",
  "Welcome, interstellar stranger! To get your hands on those resources, logging in is a must—think of it as your VIP pass.",
  "Hello, unknown traveler! Mining resources needs a login. Without it, you’re just a spectator in the grand game of extraction.",
  "Ah, the curious visitor arrives! To start mining, you’ll need to log in. It’s the key to unlocking your resource-rich adventure.",
  "Salutations, cosmic explorer! Resources await, but first, you need to log in. Consider it your entry ticket to the mining extravaganza.",
  "Ah, a new face in the quantum realm! Logging in is necessary to mine resources. It’s like getting your passport stamped for a stellar journey.",
  "Welcome back, intrepid soul! Mining resources requires logging in. It’s your gateway to diving into the cosmic treasure hunt.",
  "Ahoy, space traveler! Ready to mine some resources? Make sure you’re logged in first—think of it as your starship’s access code."
];

const miningInProgress = [ 
  "Ah, the time-bending saga continues! Mining might take a bit—just like how a pickle waits for the perfect brine.",
  "Behold, the cosmic dance of resources! Give it a moment; mining is a bit like waiting for your interdimensional portal to calibrate.",
  "Well, if it isn't the temporal wanderer! Mining’s on hold, like a TV show binge with too many ads.",
  "Greetings from the multiverse! Mining is in progress; it’s like waiting for a wormhole to spit you out in the right dimension.",
  "Ah, the mystical pause of the cosmos! Your mining progress is akin to a glitch in the matrix—hang tight!",
  "Ah, the intergalactic shuffle! Mining's a bit stalled—like trying to catch a shooting star with a fishing net.",
  "Welcome to the cosmic waiting room! Mining's on pause, sort of like your brain needing a reboot after a quantum leap.",
  "Hello, time-traveling adventurer! The mining process is in limbo, much like a glitch in the space-time continuum.",
  "Ah, the great galactic pause! Mining’s like a slow-motion sequence in a high-speed chase through the cosmos.",
  "Greetings, universe voyager! Mining is temporarily suspended—kind of like waiting for your spaceship to refuel in an asteroid field."
];

const messagesNotLoggedIn = [
  "Welcome, elusive traveler! Haven't seen you around for a while.",
  "Ah, the mystery guest returns! What’s cooking?",
  "Look who’s back in the quantum zone! How’s it going?",
  "Greetings, temporal drifter! Ready to rejoin the cosmic dance?",
  "Oh, look who decided to drop in! How’s life in the interstellar void?",
  "Well, well, if it isn’t our favorite wanderer! Missed you?",
  "Back from the great unknown, huh? What’s new?",
  "Ah, the intergalactic explorer returns! How’s your journey?",
  "Look who’s reappeared from the cosmic ether! What’s the news?",
  "Ahoy, spacefarer! What new adventures have you been on?"
];

const messagesLoggedIn = (username) => [
  `Greetings, ${username}, organic life form!`,
  `What’s shakin’, ${username}, brain sack?`,
  `Hello, ${username}, interdimensional traveler!`,
  `Salutations, ${username}, biological entity!`,
  `Hey there, ${username}, carbon-based being!`,
  `Howdy, ${username}, quantum squishy!`,
  `Yo, ${username}, meat-based construct!`,
  `Hiya, ${username}, synaptic stimulation!`,
  `What’s up, ${username}, cosmic anomaly?`,
  `Salutations, ${username}, stardust settler!`
];

const messagesLoggedOut = [
  "Oh, someone’s out of the loop! Hope you’re not lost in the space-time continuum!",
  "Looks like you’ve wandered off into the void! Try logging back in.",
  "Did you get stuck in an alternate dimension? Come back and rejoin us!",
  "Out of the system, huh? Don’t leave us floating in the cosmic abyss!",
  "Seems like you’ve slipped through the cracks of reality. Time to log back in!",
  "Oh dear, looks like you’ve vanished into the great unknown! Reconnect to reality!",
  "Lost in the digital void? Let’s get you back online!",
  "Uh-oh, looks like you’ve teleported out of the system! Come back to the fold!",
  "You’ve gone rogue in the digital expanse! Return to the login screen!",
  "Did you accidentally hit the cosmic eject button? Re-enter the digital realm!"
];

const cosmicBlenderLines = [
  "Alright, time to toss a whole bunch of you into the cosmic blender! *BURP* Multiverse Morty shake, coming right up!",
  "Hey let’s blend a bunch of you up in the cosmic blender! *BURP* Multiverse Morty smoothie, here we go!",
  "Time to mix a whole bunch of Mortys in the cosmic blender—hope you like your smoothies extra confused!",
  "Let’s crank up the cosmic blender and throw a heap of Mortys in there! *BURP* Blended Morty madness!",
  "Get ready for a cosmic blender party—throw a bunch of you in and hit ‘mix’!",
  "Throwing a pile of Mortys into the cosmic blender—let’s see what kind of mess we get!",
  "Alright, let’s dump a load of you into the cosmic blender and see what comes out!",
  "Mixing up a Morty smoothie in the cosmic blender—hold onto your existential dread!",
  "Let’s blend up a few of you in the cosmic blender—*BURP* prepare for a multi-dimensional mess!",
  "Let’s toss a bunch of Mortys into the cosmic blender—time to shake things up in the multiverse!"
];

const refMessageOne = (username) => [
  `Referral Link: https://ricksmultiverse.com/?ref=${username}`
];

// Resource list
const resources = ['Crystals', 'Plumbus', 'DarkMatter', 'PortalEnergy', 'Schmeckles'];

const tradeValues = {
  'AlienTech': 1000,
  'Schmeckles': 800,
  'PortalEnergy': 10,
  'DarkMatter': 5,
  'Crystals': 2,
  'Plumbus': 1
};

// Function to get referral code from URL
const getReferralCodeFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('ref'); // 'ref' is the query parameter for referral for example https://ricksmultiverse.com?ref=eosaccount)
};

function App() {
  //const authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN0YXRpY1VzZXIiLCJpYXQiOjE3MjYyNDE5ODh9.m9oTyQ9N3eWWxWYggKAEC_p3wQl2WeQieeUdfdX3eJA';
  const [referralCode, setReferralCode] = useState(null);

  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [referralMsg, setRefMessage] = useState('');

  const [showMore, setShowMore] = useState(false); // State to manage "More" dropdown
  const [LoggedinOnce, setLoggedinOnce] = useState(0); // Track if the user was logged in at least once
  const [currentPage, setCurrentPage] = useState('balance');  // State to manage navigation

  const [balance, setBalance] = useState({ schmeckles: 0, alientech: 0, portalenergy: 0, crystals: 0, darkmatter: 0, plumbus: 0, mortys: 0 });
  const [message, setMessage] = useState('');

  const [messageMine, setMessageMine] = useState('');  // Mining-specific message state
  const [canMine, setCanMine] = useState(false); // Track if mining is possible
  const [miningPower, setMiningPower] = useState(1); // To track additional mining power
  const [mortysToSacrifice, setMortysToSacrifice] = useState(0); // For managing input value

  const [leaders, setLeaders] = useState([]);
  const [resourceMessage, setResourceMessage] = useState('');  // New state for resource collection messages

  // States are defined but not used, purely for form layout
  const [resourceToTrade, setResourceToTrade] = useState(resources[0]);
  const [resourceToReceive, setResourceToReceive] = useState(resources[1]);
  const [amount, setAmount] = useState(0.01);
  const [amountToReceive, setAmountToReceive] = useState(0);

  // Separate mining message logic
  const getRandomMiningMessage = () => {
    const randomMessage = miningInProgress[Math.floor(Math.random() * miningInProgress.length)];
    setMessageMine(randomMessage);  // Use setMessageMine for mining messages
  };

  // General referral link
  const generateRefMessage = () => {
    let messagesOne = '';
    if (session) {
      messagesOne = refMessageOne(user);
    }

    const randomMessageOne = messagesOne[Math.floor(Math.random() * messagesOne.length)];
    setRefMessage(randomMessageOne);
  };

  // General message logic
  const generateRandomMessage = () => {
    let messages;
    if (session) {
      messages = messagesLoggedIn(user);
    } else if (!session && LoggedinOnce === 1) {
      messages = messagesLoggedOut;
    } else {
      messages = messagesNotLoggedIn;
    }
    const randomMessage = messages[Math.floor(Math.random() * messages.length)];
    setMessage(randomMessage);
  };

  const fetchBalance = async () => {
    if ((session && currentPage === 'balance') || (session && currentPage === 'trade')) {
      try {
        const response = await fetch(`https://ricksmultiverse.com/api/balance?username=${user}`);

        const data = await response.json();
        if (!data.error) {
          setBalance(data);
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    } 
  };

  // Check mining progress
  const checkMiningProgress = async () => {
    if (session && currentPage === 'mine') {
      try {
        const response = await fetch(`https://ricksmultiverse.com/api/miningprogress?username=${user}`);
        const data = await response.json();

        // Log the raw miningprogress value
        // console.log('Raw miningprogress value:', data.miningprogress);

        if (data.error) {
          setCanMine(false);
          getRandomMiningMessage();  // Use getRandomMiningMessage for mining messages
        } else {
          // Get current UTC time
          const now = new Date();
          const nowUTC = now.getTime(); // Get current time in milliseconds
          console.log('Current UTC time (milliseconds):', nowUTC);
          console.log('Current UTC time (ISO string):', now.toISOString());

          // Get last mining time from the server
          const lastMiningTime = new Date(data.miningprogress);
          const lastMiningTimeUTC = lastMiningTime.getTime(); // Get last mining time in milliseconds
          console.log('Last mining time in milliseconds:', lastMiningTimeUTC);
          console.log('Last mining time in UTC (ISO string):', lastMiningTime.toISOString());

          // Calculate the difference in hours
          const diffMilliseconds = nowUTC - lastMiningTimeUTC;
          const diffHours = diffMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours

          console.log('Difference in hours:', diffHours);

          if (diffHours >= 0.01) {
            setCanMine(true);
          } else {
            setCanMine(false);
            getRandomMiningMessage();  // Use getRandomMiningMessage for mining messages
          }
        }
      } catch (error) {
        console.error('Error fetching mining progress:', error);
      }
    }
  };

  const fetchLeaders = async () => {
    try {
      const response = await fetch('https://ricksmultiverse.com/api/leaders');
      const data = await response.json();
      if (!data.error) {
        setLeaders(data.leaders);
      }
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
    }
  };

  // The empty array ([]) as the second argument to useEffect ensures that the effect will run only once when the component mounts.
  useEffect(() => {
    // Capture referral code when app initializes
    const refCode = getReferralCodeFromUrl();
    if (refCode) {
      setReferralCode(refCode);
      localStorage.setItem('referralCode', refCode); // Optionally store it in local storage
    }
  }, []);

  useEffect(() => {
    generateRandomMessage();

    if ((session && currentPage === 'balance') || (session && currentPage === 'trade')) {
      fetchBalance();
    } else if (!session) {
        // Set default values with setBalance()
        setBalance({
            schmeckles: 0,
            alientech: 0,
            portalenergy: 0,
            crystals: 0,
            darkmatter: 0,
            plumbus: 0,
            mortys: 0
        });
    }

    if (session && currentPage === 'mine') {
      // Define async function for fetching mining power
      const fetchMiningPower = async () => {
        try {
          // Fetch the response and parse the JSON data
          const response = await fetch(`https://ricksmultiverse.com/api/getminingpower?username=${user}`);

          const data = await response.json(); // Convert the response to JSON

          // Set the actual miningpower from the response
          setMiningPower(data.miningpower);
  
        } catch (error) {
          console.error("Error fetching mining power:", error);
        }
      };

      fetchMiningPower(); // Call the async function
      checkMiningProgress();
    }

    if (currentPage === 'leaders') {
      fetchLeaders();
    }
  }, [session, currentPage ]);  // Trigger fetch when session or currentPage changes

  useEffect(() => {
    // Calculate amount to receive based on trade value
    const calculateAmountToReceive = () => {
      const valueToTrade = tradeValues[resourceToTrade];
      const valueToReceive = tradeValues[resourceToReceive];

      if (valueToTrade === 0 || valueToReceive === 0) {
        return 0;
      }
      return ((amount * valueToTrade) / valueToReceive).toFixed(2);
    };

    setAmountToReceive(calculateAmountToReceive());
  }, [amount, resourceToTrade, resourceToReceive]);

  const handleClick = async () => {
    if (!session) {
      const randomMessage = pleaseLoginToMine[Math.floor(Math.random() * pleaseLoginToMine.length)];
      setMessageMine(randomMessage);  // Set mining message for login requirement
      return;
    }

    if (canMine) {
      try {
        const response = await fetch(`https://ricksmultiverse.com/api/collect?username=${user}&miningPower=${miningPower}`, { method: 'POST' });
        const data = await response.json();
        setResourceMessage(data.message);  // Set mining-specific message

        // Update balance
        if (data.balance) {
          setBalance(data.balance);
        }

        // Check mining progress again
        checkMiningProgress();

      } catch (error) {
        console.error('Error fetching data:', error);
        setMessageMine('Failed to fetch message');  // Mining-specific error message
      }
    }
  };

  // Function to send referral information to the server
  const sendReferralToServer = async (username, referralCode) => {
    try {
       const response = await fetch(`https://ricksmultiverse.com/api/ref?username=${username}&referralCode=${referralCode}`, { method: 'POST' });

      if (!response.ok) {
        throw new Error('Failed to process referral');
      }

      const result = await response.json();
      console.log('Referral processed:', result);

    } catch (error) {
      console.error('Error in processing referral:', error);
    }
  };

  const handleLoginStatusChange = useCallback((session) => {
    if (session) {
      setUser(session.actor); // Set the user from session.actor
      setSession(session); // Set the session state
      setLoggedinOnce(1);
      generateRefMessage();

      // Fetch balance when the user logs in and current page is balance or trade
      if ((session && currentPage === 'balance') || (session && currentPage === 'trade')) {
        fetchBalance();
      }

      // After the user logs in, check for referral code
      const referralCode = localStorage.getItem('referralCode');
      if (referralCode) {
        sendReferralToServer(session.actor, referralCode); // Send referral and username
      localStorage.removeItem('referralCode'); // Optionally clear the referral code after sending it
      }

    } else {
      setUser(null); // Clear user info when logged out
      setSession(null); // Clear session
      generateRefMessage();

      // Set default balance values
      setBalance({
        schmeckles: 0,
        alientech: 0,
        portalenergy: 0,
        crystals: 0,
        darkmatter: 0,
        plumbus: 0,
        mortys: 0
      });
    }
  }, [currentPage]);

  const handleSacrificeClick = async () => {
    if (!session) {
      console.error('No active session, please login first');
      return;
    }

    if (!session) {
      console.error('User not logged in');
      return;
    }

    if (mortysToSacrifice <= 0) {
      console.error('Invalid amount');
      return;
    }

    try {
      // Prepare the transaction arguments

      const randomSacrificeMessage = cosmicBlenderLines[Math.floor(Math.random() * cosmicBlenderLines.length)];
      
      const formattedMortys = mortysToSacrifice.toFixed(4);

      const transactionArgs = {
        actions: [
          {
            account: 'mrpoopybutt1', // Smart contract account
            name: 'transfer', // Action name
            authorization: [{ actor: user, permission: 'active' }], // Authorization details
            data: {
              from: user, // Sender's account
              to: 'rickmltverse', // Recipient account
              quantity: `${formattedMortys} MORTYS`, // Amount of MORTYS to transfer
              memo: randomSacrificeMessage, // Optional memo
            },
          },
        ],
      };

      // Perform the transaction using the session's transact method
      const result = await session.transact(transactionArgs);
      console.log('Transaction successful:', result);

      const transactionId = result.response.transaction_id;
      //const transactionId = '258b6499b11b4a9fe66e2d849930f42acf5e10469af545461da20f42fd87558e';
      console.log('Transaction ID:', transactionId);

      // Pass the transaction ID to the server and fetch the mining power
      const response = await fetch(`https://ricksmultiverse.com/api/checkTransaction?transactionId=${transactionId}&username=${user}&balmortys=${balance.mortys}`, { method: 'POST' });  
      const data = await response.json();

      const { miningAmount } = data;

      // Update the mining power display
      setMiningPower(miningAmount); 

    } catch (error) {
      console.error('Transaction failed:', error);
    }
  };

  const handleClaimClick = async () => {
    if (!session) {
      console.error('No active session, please login first');
      return;
    }

    if (!session) {
      console.error('User not logged in');
      return;
    }

    try {
      const response = await fetch('https://ricksmultiverse.com/api/claim', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user })
      });

      const result = await response.json();
      if (response.ok) {
        console.log('Claim successful:', result);
        setMessage('Claim successful! Check your account for MORTYS.');
      } else {
        console.error('Error claiming rewards:', result.error);
        setMessage('Error claiming rewards: ' + result.error);
      }
    } catch (err) {
      console.error('Error handling claim:', err);
      setMessage('Error handling claim: ' + err.message);
    }
  }

  const handleTradeSubmit = async (e) => {
    e.preventDefault();

    if (amount < 0.01) {
      alert("Amount must be at least 0.01.");
      return;
    }

    try {
      // Implement trade logic here
      const response = await fetch('https://ricksmultiverse.com/api/trade', { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
        resource1: resourceToTrade,
        resource1amount: amount,
        resource2: resourceToReceive,
        resource2amount: amountToReceive,
        user: user
        })
      });

      if (!response.ok) {
        throw new Error('Failed to execute trade');
      }
    
      const result = await response.json();
      console.log('Trade result:', result);

      fetchBalance();
    } catch (error) {
      console.error('Error in trade submit:', error);
      fetchBalance();
    }
  };

  const renderPage = () => {
    console.log('Rendering page:', currentPage);  // Debugging line
    switch (currentPage) {
      case 'balance':
        return (
          <div>
            <h1>Rick's Multiverse Conquest</h1>
            <h2>presented by the Council of Ricks</h2>
            <br />
            <div className="login-section">
              <p className="login-message highlighted-message">{message}</p>
              <LoginButton
                onLogin={handleLoginStatusChange}
                showLogout={session && currentPage === 'balance'}
              />
            </div>
            <p className="description">
              You’re part of a team led by Morty, tasked with rebuilding civilizations across multiple universes destroyed by Rick's adventures. Collect resources like plumbus, crystals, dark matter, portal energy or alien tech to build infrastructure to earn Schmeckles - the ingame currency. Build weapons to fight invaders, and compete in tournaments to create the most powerful multiverse empire. Challenges and setbacks, thanks to Rick’s interference, will keep you on your toes!<br /><br />

              Listen up! This game’s in beta, which means it’s like an experimental portal gun prototype - lots of cool stuff, but it’s still a work in progress. So if things get wonky or don’t work perfectly, just roll with it. We’re still tinkering and tweaking, alright?<br /><br />
            </p>
            <center>
            <div className="balance-section">
              <h2>Balances:<span className="balance-placeholder"></span></h2>
              <p>MORTYS: {balance.mortys}</p>
              <p>Schmeckles: {balance.schmeckles}</p>
              <p>Alien Tech: {balance.alientech}</p>
              <p>Portal Energy: {balance.portalenergy}</p>
              <p>Dark Matter: {balance.darkmatter}</p>
              <p>Plumbus: {balance.plumbus}</p>
              <p>Crystals: {balance.crystals}</p>
            </div>
            </center><br /><br /><br />
          </div>
        );
        
    case 'mine':
      return (
        <div>
          <h1>Mine Resources</h1>
          <br />
            <div className="login-section">
              <p className="login-message highlighted-message">{message}</p>
              <LoginButton
                onLogin={handleLoginStatusChange}
                showLogout={session && currentPage === 'balance'}
              />
            </div>

          <p className="description">
            Alright, time to get your hands dirty! Start mining resources from all over the multiverse to fuel your empire. These resources are key for building up your infrastructure, which will keep churning out more goodies over time. Just click on the spinning image to snag materials like plumbus, crystals, dark matter, portal energy, and alien tech. Get on it, and watch your empire grow like a cosmic beanstalk!
          </p>
          <p>
            Alright, listen up! Toss a bunch of <a href="https://eos.alcor.exchange/trade/mortys-mrpoopybutt1_eos-eosio.token" target="_new">MORTYS</a> into the cosmic blender, and you'll mine like a maniac. The more MORTYS you throw in, the crazier your mining power gets. And here’s the kicker: if you buy MORTYS over-the-counter (OTC) from the Council of Ricks, we’ll juice up your mining power by 20% for that account. So go big or go home! Wubba lubba dub dub! 
          </p>
          <div className="edit-container">
            <input
              type="number"
              className="edit-box"
              value={mortysToSacrifice}
              onChange={(e) => {
                const inputValue = parseFloat(e.target.value);
                // Check if the value is greater than 0
                if (!isNaN(inputValue) && inputValue > 0) {
                  // Set state with value formatted to 4 decimal places
                  setMortysToSacrifice(Number(inputValue.toFixed(4)));
                } else {
                  // Reset state to 0 if the value is not greater than 0
                  setMortysToSacrifice(0);
                }
              }}
              min="0"
              placeholder="Enter amount of MORTYS"
            />
            <button className="sacrifice-button" disabled={!session} onClick={handleSacrificeClick}>
              Sacrifice MORTYS
            </button>
            <p>Mining Power: {miningPower}</p>
          </div>

          {!canMine && session && (
            <p className="highlighted-message">{resourceMessage}</p>  // Display resource collection message first
          )}

          {!canMine && session ? (
            <div>
              <p className="highlighted-message">{messageMine}<a href="#refresh" onClick={() => window.location.reload()} className="refresh-link"> -> Refresh Page</a></p>  {/* Display mining progress message if not allowed to mine */}	   
              <img
                src={mine}
                className="Mine-in-progress"
                alt="mine"
              />
            </div>
          ) : (
            <img
              src={logo}
              className="Mine-image"
              alt="logo"
              onClick={handleClick}
            />
          )}

        </div>
      );

      case 'build':
        return (
          <div>
            <h1>Upgrade Weapons, Structures & Technology</h1>
            <br />
            <div className="login-section">
              <p className="login-message highlighted-message">{message}</p>
              <LoginButton
                onLogin={handleLoginStatusChange}
                showLogout={session && currentPage === 'balance'}
              />
            </div>

            <p className="description">
              Alright, here’s the scoop: use those resources you’ve dug up to pimp out your farms and tech factories. You can unlock some seriously rare tech that'll boost your resource game and give you sweet new abilities. Each upgrade gets you one step closer to ruling the entire freakin' multiverse. So, get upgrading and make your empire unstoppable!
            </p>

            <div className="glowing-message"><br />
              Whoa, hold your portal guns, space cadet! Looks like this page is still in the cosmic workshop. Our interdimensional techs are slamming away at the code like it’s a Glapflapian pinata. Stay tuned for updates, and keep your schmeckles ready—things are about to get wild!
            </div>

      {/* Craftable Objects and Businesses */}
       <h4>Craftable Waepons and Infrastructure (under construction)</h4>

      <div className="card-container">
        <div className="card">
          <h3>Dark Matter Cannon</h3>
          <p><strong>Cost:</strong> 15 Schmeckles, 200 Dark Matter, 1000 Crystals</p>
          <p><strong>Combat Advantage:</strong> Defend Against Intruders or Engage in Combat with Rivals</p>
        </div>
        <div className="card">
          <h3>Portal Shield</h3>
          <p><strong>Cost:</strong> 15 Schmeckles, 100 Portal Energy, 800 Crystals</p>
          <p><strong>Active Defense:</strong> Defend Against Intruders or Engage in Combat with Rivals</p>
        </div>
        <div className="card">
          <h3>Portal Gun</h3>
          <p><strong>Cost:</strong> 30 Schmeckles, 500 Dark Matter, 1 Alien Tech, 800 Portal Energy, 200 Crystals</p>
          <p><strong>Strong Combat Advantage:</strong> Defend Against Intruders or Engage in Combat with Rivals</p>
        </div>
        <div className="card">
          <h3>Dimensional Trap</h3>
          <p><strong>Cost:</strong> 30 Schmeckles, 50 Dark Matter, 1 Alien Tech, 500 Portal Energy, 500 Crystals</p>
          <p><strong>Strong Active Defense:</strong> Defend Against Intruders or Engage in Combat with Rivals</p>
        </div>
        <div className="card">
          <h3>Spaceship</h3>
          <p><strong>Cost:</strong> 100 Schmeckles, 1 Alien Tech, 1000 Portal Energy, 10000 Crystals, 5000 Dark Matter, 200 Plumbus</p>
          <p><strong>Most powerful Combat Advantage:</strong> Defend Against Intruders or Engage in Combat with Rivals</p>
        </div>
        <div className="card">
          <h3>Energy Barrier</h3>
          <p><strong>Cost:</strong> 100 Schmeckles, 1 Alien Tech, 2000 Portal Energy, 1000 Crystals, 8000 Dark Matter</p>
          <p><strong>Most powerful Active Defense:</strong> Defend Against Intruders or Engage in Combat with Rivals</p>
        </div>
        <div className="card">
          <h3>Farm (Generates Schmeckles)</h3>
          <p><strong>Cost:</strong> 500 Portal Energy, 5000 Plumbus, 10000 Crystals</p>
          <p><strong>Steady Resource Accumulation:</strong> 1 Schmeckle/day</p>
        </div>
        <div className="card">
          <h3>Tech Factory (Generates Schmeckles)</h3>
          <p><strong>Cost:</strong> 1 Alien Tech, 1000 Portal Energy, 6000 Plumbus, 8000 Crystals</p>
          <p><strong>Steady Resource Accumulation:</strong> 5 Schmeckles/day</p>
        </div>
      </div>

          </div>
        );

      case 'events':
        return (
          <div>
            <h1>Dimensional Battles and Challenges</h1>
            <br />
            <div className="login-section">
              <p className="login-message highlighted-message">{message}</p>
              <LoginButton
                onLogin={handleLoginStatusChange}
                showLogout={session && currentPage === 'balance'}
              />
            </div>

            <p className="description">
              Rick’s latest experiments have pissed off some interdimensional invaders! Now you gotta use your resources to fend them off in epic, high-stakes showdowns. Only the boldest will make it through these cosmic brawls and snag those sweet, powerful rewards. Gear up and show those invaders who's boss!
            </p>
           
            <div className="glowing-message"><br />
              Whoa, hold your portal guns, space cadet! Looks like this page is still in the cosmic workshop. Our interdimensional techs are slamming away at the code like it’s a Glapflapian pinata. Stay tuned for updates, and keep your schmeckles ready—things are about to get wild!
            </div>
          </div>
        );

        case 'leaders':
    return (
        <div>
            <h1>Leaderboard</h1>
                <br/>
                <div className="login-section">
                <p className="login-message highlighted-message">{message}</p>
                <LoginButton onLogin={handleLoginStatusChange} showLogout={session && currentPage === 'balance'} />
            </div>
            <p className="description"><center>Alright, check it out: here’s the rundown of the top players and their stash of Schmeckles. These are the big shots with the cosmic bling, showing off their interdimensional cash like they own the multiverse. Keep an eye on this list and maybe learn a thing or two from the best of the best!<br /><br />Leaderboard hash (contract rickmltverse):<br />5eb6574dea7f56b221216102920f00236e084daef7685fc90a4d741d44b941bc</center><br /></p>
            <div className="leaderboard">
              {leaders.map((leader, index) => (
                <div
                  key={index}
                  className={`leader ${index < 3 ? 'top-three' : ''}`}
                  style={{ marginBottom: index === 2 ? '30px' : '10px' }} // Adjust marginBottom as needed
                >
                  {index === 0 && <span className="crown">👑 Schwifty</span>}
                  {index === 1 && <span className="crown">👑 Glorzo</span>}
                  {index === 2 && <span className="crown">👑 Blips</span>}
                  {index > 2 && <span className="rank">{index + 1}th</span>}
                  <span className="username">{leader.eosaccount}</span>: <span className="schmeckles">{leader.schmeckles}</span>
                </div>
              ))}
            </div>
            
        </div>
    );

    case 'faq':
        return (
            <div>
                <h1>Read it if you wanna be less clueless. It’s important</h1>
		<br/>
                    <div className="login-section">
                    <p className="login-message highlighted-message">{message}</p>
                    <LoginButton onLogin={handleLoginStatusChange} showLogout={session && currentPage === 'balance'} />
                </div>
                <p className="description">Schmeckles, baby! That’s the currency in Rick and Morty-land, you clueless loser. It’s our in-game loot now, and who knows what wild value it might have in the future. But listen up - if Schmeckles ever do get any value down the line, it'll only be for the players who signed up at <a href="https://eossupport.io/signup" target="_blank" rel="noopener noreferrer">eossupport.io/signup</a>. And yeah, that site? It’s the freakin' support platform for the game, so don’t be a dope - get over there and sign up if you don’t wanna miss out!<br /><br />

                Oh, and here’s a little nugget of cosmic wisdom: We’re cooking up some fancy logic to make sure only one unique user per account gets the goodies. So don’t even think about playing the system with multiple EOS accounts. If Schmeckles do get a value, anyone trying to game it with multiple accounts will find themselves losing everything. Don’t cheat, or you’ll just be a cosmic zero!<br /><br />
                </p>
                <p>
<b>Q: How can I support this game?</b><br />
A: Support, huh? Simple. Play the damn game every day! Scoop up some MORTY tokens OTC if you wanna back the Council of Ricks. Then, sacrifice those MORTYs to boost your mining mojo. It’s all about keeping the Ricks’ empire strong!<br /><br />

<b>Q: So, uh, what kind of resources am I gonna get while mining?</b><br />
A: Alright, listen up! When you go mining in this interdimensional s**tshow, you’re basically rolling the dice. Here’s how it breaks down:<br /><br />

There's a tiny 0.1% chance you'll snag some rare-as-hell alientech—just one though, so don’t get cocky.<br />
Then there's about a 1% chance you’ll grab some schmeckles (yeah, currency is still a thing, Morty). Could be anywhere between 1 and 5 schmeckles.<br />
A 10% chance of mining some portalenergy (useful for, you know, not getting trapped in a dimension full of Cronenbergs). That’ll get you somewhere between 1 and 100 units.<br />
A 15% chance you’ll scoop up darkmatter (the good stuff, real heavy-duty material). You’ll grab between 1 and 50 units of that.<br />
And then there’s a whopping 35% chance you'll end up with crystals. They're shiny, and you'll probably get between 1 and 100 of 'em.<br />
If all else fails (and by 'all else,' I mean you being unlucky), you’ll wind up with plumbus, which are... Well everybody’s got a plumbus, Morty. You’ll get somewhere between 1 and 50 of these things.<br /><br />
<b>Q: How long does this mining gig take, Rick?</b><br />
A: Ugh, don't be impatient! Mining takes approximately 2 hours, alright? Give or take some quantum fluctuations, but yeah—2 hours.<br /><br />

<b>Q: Q: How do you make sure the leaderboard data doesn’t get messed with?</b><br />
A: Alright, here's the deal: To keep things from turning into a free-for-all, we hash the leaderboard and dump it into a smart contract once a day. That means your scores are locked up tighter than a Zorp’s wallet. If the game blows up and we’ve got some cash to burn, we might even throw in a real smart contract with the leaderboard and put some schmeckles on the blockchain for trading.<br /><br />


<b>Q: Rick, how does the mining power get twisted up with how many MORTYS you sacrifice versus what you keep?</b><br />

A: Alright, strap in! If you toss a ginormous chunk of your MORTYS into the cosmic blender—like, if you’ve got 10 million and you fling a hefty amount away—you’re gonna crank up your mining power to the max, up to 10, especially if you’re sacrificing a whole lot compared to what’s left in your stash.

But here’s where it gets wild: the bigger the chunk you sacrifice, the crazier your mining power becomes. Sacrifice a massive pile, and your mining power goes through the roof. If you’re just tossing in a tiny fraction—like if you’ve got a million MORTYS and only throw a few thousand into the mix—your mining power stays very low.

So, to sum it up: the more MORTYS you throw into the blender versus what you’re keeping, and the bigger the chunk you sacrifice, the more your mining power skyrockets. Big sacrifices mean big boosts, while tiny sacrifices keep you on the lower end. It’s all about how much you’re tossing away compared to what you’re holding on to!<br /><br />

<b>Q: So, anyone can just hit the "Claim MORTYS" button and bail, huh?</b><br />

A: Yep, that’s the deal. When someone cashes out, 40% of the MORTYS in the game’s EOS account gets split up among all the players. Yeah, so the one cashing out grabs their chunk of the pot, and it’s all about how many Schmeckles they’re rocking. The more Schmeckles you’ve got, the juicier your slice of the pot is.<br /><br />

<b>Q: What happens if MORTYS tokens actually become valuable?</b><br />

A: Oh, you betcha! If MORTYS hit it big, some of ‘em might be sold to fund the devs. Don’t go flipping your lid if you see some tokens moving around to keep the creators in business. It’s all part of the plan!<br /><br />

<b>Q: What’s the deal with the game being in beta and the whole responsibility thing?</b><br />

A: Oh, listen up! This game’s in beta, so it’s still a wild ride, you know? The Council of Ricks and the developers aren’t gonna take any blame if you lose your schmeckles or whatever. We also reserve the right to hit the brakes, make changes, or even move the game to a different token or blockchain anytime we feel like it. But don’t sweat it - if we switch things up or move to a new blockchain with a new token, all your schmeckles and scores will stay put. We’ll airdrop the new tokens straight to the players. Just roll with it and enjoy the ride!<br /><br />
                </p>

<p className="description">Most of this crazy web game was whipped up with the help of ChatGPT from OpenAI, and Grok’s been slappin’ together the visuals! Time sunk into this interdimensional madness so far? A solid 56 hours of mind-melting chaos! Wubba lubba dub dub!</p>
           </div>
      );

      case 'tournaments':
        return (
          <div>
            <h1>Compete in Tournaments</h1>
            <br />
            <div className="login-section">
              <p className="login-message highlighted-message">{message}</p>
              <LoginButton
                onLogin={handleLoginStatusChange}
                showLogout={session && currentPage === 'balance'}
              />
            </div>

            <p className="description">
              Alright, here's the lowdown: jump into those daily or weekly tournaments if you want a shot at scoring Schmeckles and some seriously cool loot. Show off your multiverse muscle and go head-to-head with other players in PvP brawls. It’s your chance to prove that your empire is the baddest in the cosmos. Get in there, kick some interdimensional butt, and claim those rewards!
            </p>
                <div className="glowing-message"><br />
  Whoa, hold your portal guns, space cadet! Looks like this page is still in the cosmic workshop. Our interdimensional techs are slamming away at the code like it’s a Glapflapian pinata. Stay tuned for updates, and keep your schmeckles ready—things are about to get wild!
                </div>
          </div>
        );

      case 'trade':
        return (
          <div>
            <h1>Trade</h1>
            <br />
            <div className="login-section">
              <p className="login-message highlighted-message">{message}</p>
              <LoginButton
                onLogin={handleLoginStatusChange}
                showLogout={session && currentPage === 'balance'}
              />
            </div>

            <p className="description">
              Alright, here’s the deal: this is the Trade page, where you can swap your stash of resources for other resources anytime you want. Need more Dark Matter? Just trade some Crystals! But wait, there's more! When buyers & sellers are around, you can also trade shiny objects and infrastructure. So, if you’ve got the goods and you’re looking to make a deal, this is your cosmic marketplace. Get those trades rolling and keep your empire in tip-top shape!
            </p>
            
<div className="trading-balance-container">
  <div className="trading-section">
    <form onSubmit={handleTradeSubmit}>
      <label>
        Resource to Trade:
        <select value={resourceToTrade} onChange={(e) => setResourceToTrade(e.target.value)}>
          {resources.map(resource => (
            <option key={resource} value={resource}>{resource}</option>
          ))}
        </select>
      </label>
      <br />
      <label>
        Amount:
        <input 
          type="number" 
          value={amount} 
          onChange={(e) => setAmount(Number(e.target.value))} 
          min="0.01" 
          step="0.01" 
        />
      </label>
      <br />
      <label>
        Resource to Receive:
        <select value={resourceToReceive} onChange={(e) => setResourceToReceive(e.target.value)}>
          {resources.map(resource => (
            <option key={resource} value={resource}>{resource}</option>
          ))}
        </select>
      </label>
      <br />
      <p>Amount of {resourceToReceive} to receive: {amountToReceive}</p>
      <br />
      <button type="submit" disabled={!session}>Trade it!</button>
    </form>
  </div>

  <div className="balance-section">
    <h2>Balances:<span className="balance-placeholder"></span></h2>
    <p>MORTYS: {balance.mortys}</p>
    <p>Schmeckles: {balance.schmeckles}</p>
    <p>Alien Tech: {balance.alientech}</p>
    <p>Portal Energy: {balance.portalenergy}</p>
    <p>Dark Matter: {balance.darkmatter}</p>
    <p>Plumbus: {balance.plumbus}</p>
    <p>Crystals: {balance.crystals}</p>
  </div>
</div>
<br /><br /><br />
          </div>
        );

     case 'referral':
  return (
    <div>
      <h1>Referral</h1>
      <br />
      <div className="login-section">
        <p className="login-message highlighted-message">{message}</p>
        <LoginButton
          onLogin={handleLoginStatusChange}
          showLogout={session && currentPage === 'balance'}
        />
      </div>

      {session && user ? (
        <div>
          <p className="description">
            Alright, listen up! Here’s the deal: <br /><br />

            If you can drag someone into this game and get them playing, you’ll score an extra +0.5 mining power for every new player you recruit. Just watch yourself - you can’t go over the max mining power limit. It’s a galactic deal to boost your game, but you gotta be smart about it!<br /><br />

            Pretty sweet, huh? But here’s the twist: our system’s gonna get some clever upgrades soon to make sure only unique users are joining the game. So don’t even think about trying to game the system with a bunch of fake EOS accounts. If you mess around and Schmeckles become a thing, cheaters will lose everything. Don’t be a knucklehead, Morty—just play it straight, or you’ll end up as a cosmic zero!
          </p>
          {/* Referral code section */}
          <p className="description">
            {referralMsg}
          </p>
        </div>
      ) : (
        <p className="description">
          Alright, listen up! Here’s the deal:<br /><br />

          If you can drag someone into this game and get them playing, you’ll score an extra +0.5 mining power for every new player you recruit. Just watch yourself - you can’t go over the max mining power limit. It’s a galactic deal to boost your game, but you gotta be smart about it!
        </p>
      )}
    </div>
  );


      case 'claim':
        return (
          <div>
            <h1>Claim Your Rewards</h1>
            <br />
            <div className="login-section">
              <p className="login-message highlighted-message">{message}</p>
              <LoginButton
                onLogin={handleLoginStatusChange}
                showLogout={session && currentPage === 'balance'}
              />
            </div>

            <p className="description">
              Alright, listen up! After you smash those milestones, win battles, or ace those tournaments, you can cash in your Schmeckles for MORTYS tokens. But hey, don’t get too hasty - Schmeckles could become the next big thing! So, think twice before you swap them out. Keep your eyes on the prize and build your multiverse empire like a pro. Who knows, those Schmeckles might be worth their weight in interdimensional gold someday!
            </p>
            
            <br />
            <div>
              {session && (
                <button className="claim-button" onClick={handleClaimClick}>
                  <span>Claim MORTYS</span>
                </button>
              )}
            </div>

          </div>
        );

      case 'gamble':
        return (
          <div>
            <h1>Gamble with Rick</h1>
            <br />
            <div className="login-section">
              <p className="login-message highlighted-message">{message}</p>
              <LoginButton
                onLogin={handleLoginStatusChange}
                showLogout={session && currentPage === 'balance'}
              />
            </div>

            <p className="description">
              Alright, Rick’s got a new gamble for you! Think you’ve got the guts to bet your Schmeckle tokens and roll the dice? Dive into Rick’s Mad Lab Experiment or the Cosmic Trivia Showdown and see what kind of cosmic chaos unfolds. But watch out - expect the unexpected!<br /><br />
              Or if you’re feeling risky, try bribing Rick to whip up and invent something wild for you. Just remember, if your bribe flops, you’ll not only lose your Schmeckles but also have to dodge the Guardians of the Galaxy. While you’re on the lam, no mining for you! Here’s how long you’ll need to hide based on who’s chasing you:
              <ul>
                <li><strong>Star-Lord:</strong> Fast, sharp, and relentless. Shaking him off is no easy feat.</li>
                <li><strong>Gamora:</strong> Expert warrior with top-notch tracking and combat skills. Her pursuit is intense and deadly.</li>
                <li><strong>Drax:</strong> Big, strong, and tough to avoid. Hiding from him takes some serious time.</li>
                <li><strong>Rocket Raccoon:</strong> Wild card with a ton of weapons and gadgets. Unpredictable and tricky to escape.</li>
                <li><strong>Groot:</strong> The chillest of the bunch. His pursuit is slow and not too urgent.</li>
              </ul>
            </p>
            
                <div className="glowing-message">
                  Whoa, hold your portal guns, space cadet! Looks like this page is still in the cosmic workshop. Our interdimensional techs are slamming away at the code like it’s a Glapflapian pinata. Stay tuned for updates, and keep your schmeckles ready—things are about to get wild!
                </div>
          </div>
        );
        
      default:
        return null;
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        {renderPage()}
      </header>

      {/* Navigation bar */}
      <nav className="App-nav">
        <button onClick={() => setCurrentPage('balance')}>Home</button>
        <button onClick={() => setCurrentPage('mine')}>Mine</button>
        <div className={`dropdown ${showMore ? 'show' : ''}`}>
          <button className="more-menu" onClick={() => setShowMore(!showMore)}>More</button>
          {showMore && (
            <div className="dropdown-content">
              <button onClick={() => setCurrentPage('faq')}>Read it dumbass</button>
              <button onClick={() => setCurrentPage('leaders')}>Leaderboard</button>
              <button onClick={() => setCurrentPage('tournaments')}>Tournaments</button>
              <button onClick={() => setCurrentPage('gamble')}>Gamble with Rick</button>
              <button onClick={() => setCurrentPage('events')}>Events</button>
              <button onClick={() => setCurrentPage('referral')}>Referral</button>
              <button onClick={() => setCurrentPage('claim')}>Claim Rewards</button>
            </div>
          )}
        </div>
        <button onClick={() => setCurrentPage('trade')}>Trade</button>
        <button onClick={() => setCurrentPage('build')}>Build</button>
      </nav>
    </div>
  );
}

export default App;