// src/sessionKit.js
import { SessionKit } from '@wharfkit/session';
import { WebRenderer } from '@wharfkit/web-renderer';
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor';

const webRenderer = new WebRenderer();

export const sessionKit = new SessionKit({
  appName: 'mortythegame', // Replace with your app name
  chains: [
    {
      id: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906', // EOS mainnet chain ID
      url: 'https://eos.eosusa.io', // Replace with your actual RPC URL
    },
  ],
  ui: webRenderer,
  walletPlugins: [new WalletPluginAnchor()],
});